export const SET_CONTACTS = 'SET_CONTACTS';
export const SET_MY_DATA = 'SET_MY_DATA';
export const SET_ME_RADIOLOGIST = 'SET_ME_RADIOLOGIST';
export const SET_ME_HOSPITAL = 'SET_ME_HOSPITAL';
export const SET_MODALITY = 'SET_MODALITY';
export const SET_PLANS = 'SET_PLANS';
export const SET_ACTIVE_BRANCH = 'SET_ACTIVE_BRANCH';
export const SET_ACTIVE_INSTITUTION = 'SET_ACTIVE_INSTITUTION';
export const SET_ALL_BRANCHES = 'SET_ALL_BRANCHES';
export const SET_RELATIONSHIP_TYPE = 'SET_RELATIONSHIP_TYPE';
export const SET_PERMISSION = 'SET_PERMISSION';
export const SET_DOCTOR_TYPE = 'SET_DOCTOR_TYPE';
