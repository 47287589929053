import crypto from 'crypto-js';

export function encrypt(data) {
  const key = `${process.env.REACT_APP_ENCRYPTION_KEY.slice(12, 15)}-${process.env.REACT_APP_ENCRYPTION_KEY.slice(
    6,
    9,
  )}-${process.env.REACT_APP_ENCRYPTION_KEY.slice(0, 3)}`;

  // If the data is a FormData instance, log its entries and convert it to an object.
  if (typeof FormData !== 'undefined' && data instanceof FormData) {
    const formDataObj = {};
    for (let [formKey, value] of data.entries()) {
      formDataObj[formKey] = value;
    }
    data = formDataObj;
  }

  // Encrypt the JSON string of the data.
  const encrypted = crypto.AES.encrypt(JSON.stringify(data), key).toString();
  return encrypted;
}

export function decrypt(data) {
  const key = `${process.env.REACT_APP_ENCRYPTION_KEY.slice(12, 15)}-${process.env.REACT_APP_ENCRYPTION_KEY.slice(
    6,
    9,
  )}-${process.env.REACT_APP_ENCRYPTION_KEY.slice(0, 3)}`;

  const decryptedBytes = crypto.AES.decrypt(data, key);
  const decryptedStr = decryptedBytes.toString(crypto.enc.Utf8);
  const result = JSON.parse(decryptedStr);
  return result;
}
