import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import videoSource from '../../../assets/video/How_it_works.mp4';
import videoHolder from '../../../assets/img/Xolani_Video_Interaction.webp';
import './style.css';
import heroMainSmall from '../../../assets/img/hero_main_small.webp';
import heroMainMedium from '../../../assets/img/hero_main_medium.webp';
import heroMainLarge from '../../../assets/img/hero_main_large.webp';
import aboutImg from '../../../assets/img/about_us_institution.webp';
import XBButton from '../../../components/XBButton';
import { institutionFeaturesData } from './components/Why/constant';
import Why from './components/Why';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import { MdPlayArrow } from 'react-icons/md';
import FullScreenVideoModal from '../../../components/FullScreenVideoModal/FullScreenVideoModal';

function InstHome() {
  const navigate = useNavigate();
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleVideoClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="bg-[#EBF6F8]">
      <Navbar />
      <section
        id="home"
        style={{
          background: 'linear-gradient(180deg, #CAEAEE 0%, #FAFFFF 53.2%, #EAF3F4 106.39%)',
        }}
        className="flex flex-col justify-center  pt-20 pb-10 sm:py-10 md:py-14 sm:px-0 md:px-5 lg:px-[20px] xl:px-[50px] bg-white w-full"
      >
        <div className="max-w-[1280px] w-full mx-auto  flex gap-10 lg:flex-col">
          <div className="p-4 flex-1  max-w-[610px] sm:w-full md:w-full flex flex-col justify-center">
            <h1 className="font-semibold text-[40px] sm:text-3xl  text-[#011E21] text-left mb-4 whitespace-nowrap xl:whitespace-normal">
              Are you a hospital
              <br className="xl:hidden md:block" />
              or diagnostic center?
            </h1>

            <p className="font-semibold text-[#828A8B] text-xl sm:text-lg md:text-lg lg:text-[18px] text-left mb-10 leading-9">
              Seamlessly connect with top radiologists anytime, anywhere, and ensure precise diagnoses and timely
              treatment for your patients.{' '}
            </p>

            <XBButton onClick={() => navigate('/app/auth/register-institution')}>Get Started</XBButton>
          </div>
          <div className="relative p-10 flex-1 w-full sm:w-full md:w-full overflow-hidden flex items-center justify-end">
            <img
              className="w-full max-w-[500px] max-h-[550px] sm:max-h-[400px] object-cover object-bottom rounded-tr-[60px] rounded-bl-[60px]"
              src={heroMainLarge}
              srcSet={`${heroMainSmall} 320w, ${heroMainMedium} 600w, ${heroMainLarge} 1080w`}
              sizes="(max-width: 600px) 100vw, (max-width: 1024px) 50vw, 500px"
              alt="hero"
            />

            <div className="absolute top-1/2 transform -translate-y-1/2 sm:pl-4 ">
              <img className="w-full max-w-[550px] rounded-[10px] object-cover" src={videoHolder} alt="videoHolder" />
              <div className="absolute w-full h-full bg-[#f5ffff] top-0 left-0 rounded-[10px] opacity-40"></div>
              <div
                onClick={handleVideoClick}
                className="h-[75px] w-[75px] bg-white rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center text-[#18181B] shadow-2xl cursor-pointer"
              >
                <MdPlayArrow size={22} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FullScreenVideoModal isOpen={isModalOpen} onClose={handleCloseModal} videoSrc={videoSource} />
      {/* About us*/}
      <section
        id="about"
        className="flex flex-col justify-center  py-20 sm:py-10 md:py-14 sm:px-0 md:px-5 lg:px-[20px] xl:px-[50px] bg-white w-full"
      >
        <div className="w-full max-w-[1280px] mx-auto  flex  justify-between md:flex-col ">
          <div className="p-4 w-1/2 sm:w-full md:w-full overflow-hidden flex items-center">
            <img className="w-full max-h-[460px] max-w-[520px] object-fill" src={aboutImg} alt="about" loading="lazy" />
          </div>
          <div className="p-10  lg:p-4  md:p-8 w-1/2 max-w-[600px] sm:w-full md:w-full flex flex-col justify-center ">
            <h1 className="font-semibold text-4xl sm:text-2xl lg:text-[28px] text-[#596061] text-left mb-4">
              What We Do
            </h1>
            <h2 className="font-medium text-xl sm:text-lg lg:text-[18px] text-[#068999] text-left mb-8 ">
              Empowering Your Radiology Team, Every Step of the Way{' '}
            </h2>
            <p className="font-medium text-[#828A8B] text-xl sm:text-lg md:text-lg lg:text-[18px] text-left leading-9">
              At XolaniBridge, we understand the daily hurdles you face in providing exceptional radiology services. The
              challenges of limited resources, a shortage of qualified radiologists, and the complexity of modern cases
              are real and demanding. That’s why we’ve built a tele-radiology platform designed with your needs in mind.
              Our solution connects you to a network of top radiologists, offering support and expertise right when you
              need it. We’re here to lighten your load and help you deliver accurate, timely diagnoses that make a real
              difference for your patients. Together, we’re redefining what’s possible in radiology.
            </p>
          </div>
        </div>
      </section>
      {/* Benefits */}
      <section id="features" className="px-6 bg-[#F5FFFF]">
        <Why benefitList={institutionFeaturesData} />
      </section>
      {/* Contact us */}
      <section
        id="contact-us"
        className="px-6 "
        style={{ background: 'linear-gradient(180deg, #FBFBFB 0%, #FAFFFF 53.2%, #EAF3F4 106.39%)' }}
      >
        <ContactUs />
      </section>
      {/* Footer */}
      <section id="footer">
        <Footer />
      </section>
    </div>
  );
}

export default InstHome;
