import React from 'react';
import { FaLinkedinIn } from 'react-icons/fa';
import { IoLogoFacebook, IoLogoWhatsapp } from 'react-icons/io5';
import { MdEmail } from 'react-icons/md';
import Logo from '../../../../../static/img/xo-bridge-v3.svg';
import './footerStyle.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className="landing_footer">
        <div className="landing_foot_top">
          <div className="foot_left_box">
            <img src={Logo} alt="logo" loading="lazy" />
            <p>Connect Instantly with Top Practitioners, Pharmacies, and Labs - Wherever You Are.</p>
            <div className="contact_social">
              <Link to="mailto:xolanihealth@gmail.com?subject=Hello" title="Send an Email">
                <MdEmail size={20} />
              </Link>
              <Link to="https://web.facebook.com/xolanihealth" target="_blank">
                <IoLogoFacebook size={20} />
              </Link>
              <Link to="https://www.linkedin.com/company/xolanihealth/posts/?feedView=all" target="_blank">
                <FaLinkedinIn size={20} />
              </Link>
            </div>
          </div>
          <div className="foot_right_box">
            <div className="foot_right_content">
              <h3>Company</h3>
              <div>
                <p>About Us</p>
                <p>FAQ</p>
                <p>Get in Touch</p>
              </div>
            </div>
            <div className="foot_right_content">
              <h3>Products</h3>
              <div>
                <p>Xolani for Patients</p>
                <p>Xolani for Doctors</p>
                <p>Xolani for Institutions</p>
              </div>
            </div>
            <div className="foot_right_content">
              <h3>Services</h3>
              <div>
                <p>Tele-Medicine</p>
                <p>Hospitals/Labs</p>
                <p>Online Pharmacy</p>
                <p>Tele-Radiology</p>
              </div>
            </div>
            <div className="foot_right_content">
              <h3>Quick Link</h3>
              <div>
                <p>Book a Consultation</p>
                <p>Join as a Patient</p>
                <p>Register as a Doctor</p>
                <p>Connect with a Lab</p>
                <p>Online Pharmacy</p>
                <p>Tele - Radiology</p>
              </div>
            </div>
            <div className="foot_right_content">
              <h3>Pricing</h3>
              <div>
                <p>Pay As You Go</p>
                <p>Monthly Insurance</p>
                <p>By Insurance</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="landing_footer_bottom">
        <div className="landing_foot_bottom">
          <div className="foot_bottom_left">
            <div className="foot_bottom_links">
              <span onClick={() => window.open('/privacy', '_blank')}>Privacy Policy</span>
              <span onClick={() => window.open('/terms-conditions', '_blank')}>Terms & Conditions</span>
              <span>Contact Us</span>
            </div>
            <p>Copyright 2021 - 2024, Xolani Health Inc. All rights reserved.</p>
          </div>
          <div className="foot_bottom_right">
            <div className="contact_social">
              <Link to="mailto:xolanihealth@gmail.com?subject=Hello" title="Send an Email">
                <MdEmail size={20} />
              </Link>
              <Link to="https://web.facebook.com/xolanihealth" target="_blank">
                <IoLogoFacebook size={20} />
              </Link>
              <Link to="https://www.linkedin.com/company/xolanihealth/posts/?feedView=all" target="_blank">
                <FaLinkedinIn size={20} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
